import React from "react"
import Layout from "../layout"
import Navbar from "../navbar"
import "./style.scss"
import MembershipForm from "../connect/MembershipForm"

function Memberships() {
  return (
    <Layout>
      <Navbar></Navbar>
      <div className="memberships">
        <div className="memberships__container">
          <hr className="hr" />
          <h1 className="heading-primary">School LIVE</h1>
          <h2>Elite Schools READ Club Membership </h2>
          <h3>
            <strong>
              <em>Awareness + Knowledge</em>
            </strong>
          </h3>
          <ul>
            <li>A monthly magazine with something for everyone!</li>
            <li>Trusted by leading educators and school principals</li>
            <li>
              Empowers school students with the right interface to achieve their
              goals
            </li>
            <li>Every issue is a Collector’s Issue</li>
            <li>Promotes joyful learning</li>
            <li>Articles that spread awareness AND enhance knowledge</li>
          </ul>
          <br></br>
          <br></br>
          <center>
            {" "}
            <bold> Submit an enquiry below to join the Elite Club today!</bold>
          </center>
          <MembershipForm />
        </div>
      </div>
    </Layout>
  )
}

export default Memberships
